<script setup lang="ts">
	import Header from "./toc-header.vue";
	import Tab from "./toc-tab.vue";
	import Sidebar from "./toc-sidebar.vue";
	import Footer from "./toc-footer.vue";
	import { useRouteStore } from "~/store/routeStore";

	const route = useRoute();

	const connPath = ref(route.fullPath);

	const checkRouteSideBarString = ["/error", "/not-found", "maintainence"] as string[];
	const checkRouteTabString = ["/error", "/not-found", "maintainence"] as string[];
	const checkRouteHeaderString = [] as string[];

  function routeCheck(routeString: string[]){
    for(let i = 0; i < routeString.length; i++){
      if(connPath.value.includes(routeString[i])){
        return false;
      }
    }

    return true;
  }

	const isSideBar = computed(() => {
    return routeCheck(checkRouteSideBarString);
	});

	const isTab = computed(() => {
    return routeCheck(checkRouteTabString);
	});

	const isHeader = computed(() => {
    return routeCheck(checkRouteHeaderString);
	});
</script>

<template>
	<div class="toc" id="toc">
		<div id="project">
			<Header v-if="isHeader"></Header>
			<Tab v-if="isTab"></Tab>
			<Sidebar v-if="isSideBar"></Sidebar>
			<slot />
			<!-- <Footer></Footer> -->
		</div>
	</div>
</template>

<style lang="scss">
	@import "../../assets/styles/toc";
</style>
